import React from 'react';
import tw from 'twin.macro';
import { Input, Avatar, Menu, Dropdown } from 'antd';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { Analytics, SearchOutlined, Filter, X, Check, MoreVertical, Late } from 'src/icons';
import getRandomString from 'src/utils/getRandomString';

export const Control: React.FC<any> = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span className="icon">
        <Filter />
      </span>
      {children}
    </components.Control>
  );
};

const ActionMenu = () => (
  <Dropdown
    placement="topRight"
    overlay={
      <Menu>
        <Menu.Item>
          <Check className="mr-3" /> Present
        </Menu.Item>
        <Menu.Item>
          <X className="mr-3" /> Absent
        </Menu.Item>
        <Menu.Item>
          <Late className="mr-3" /> Late
        </Menu.Item>
      </Menu>
    }
    trigger={['click']}
    overlayClassName="student-action-menu"
  >
    <span>
      <MoreVertical />
    </span>
  </Dropdown>
);

const Attendance = () => {
  const randomId = getRandomString(7);

  return (
    <AttendanceContainer>
      <div className="attendance-header">
        <h3>Today’s Attendance</h3>
        <span className="view">
          <Analytics className="mr-1.5" /> View Report
        </span>
      </div>
      <div className="attendance-card mt-4 mb-8">
        <div className="total item">
          <p>Total</p>
          <span>25</span>
        </div>
        <div className="present item">
          <p>Present</p>
          <span>13</span>
        </div>
        <div className="absent item">
          <p>Absent</p>
          <span>10</span>
        </div>
        <div className="late item">
          <p>Late</p>
          <span>2</span>
        </div>
      </div>
      <div className="take-attendance">
        <h3 className="mb-6">Take Attedance</h3>
        <label htmlFor={`opt${randomId}1`}>
          <input type="radio" name="opt" id={`opt${randomId}1`} />
          <span className="checkmark"></span>
          <span>Mark all students in class present</span>
        </label>
        <label htmlFor={`opt${randomId}2`}>
          <input type="radio" name="opt" id={`opt${randomId}2`} />
          <span className="checkmark"></span>
          <span>Mark only students with hand raised present</span>
        </label>
        <label htmlFor={`opt${randomId}3`}>
          <input type="radio" name="opt" id={`opt${randomId}3`} />
          <span className="checkmark"></span>
          <span>Mark only students active 90% of the class time present</span>
        </label>
        <label htmlFor={`opt${randomId}4`}>
          <input type="radio" name="opt" id={`opt${randomId}4`} />
          <span className="checkmark"></span>
          <span>Mark only the selected students present</span>
        </label>
        <div className="action">
          <button className="notify mr-2.5">Notify students</button>
          <button className="notify mr-2.5">Open Power Teacher Pro</button>
          <button className="submit">Submit</button>
        </div>
      </div>

      <div className="students mt-6">
        <div className="filter-container">
          <div className="select">
            <Select
              components={{ Control }}
              placeholder="Filter by type"
              classNamePrefix="filter"
            />
          </div>
          <div className="search">
            <Input prefix={<SearchOutlined />} placeholder="Search by name" />
          </div>
        </div>

        <div className="attendance-table">
          <div className="attendance-row header">
            <div className="col name">Students (34)</div>
            <div className="col">Attendance</div>
            <div className="col"></div>
          </div>

          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar
                src="/img/dummy/avatars/taylorProfile.jpg"
                size="default"
                // className="avatar"
              />
              <span>&nbsp;&nbsp;Sophia Lorenz</span>
            </div>
            <div className="col check">
              <Check />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar src="/img/dummy/avatars/dwight.jpeg" size="default">
                M
              </Avatar>
              <span> &nbsp;&nbsp;Dwight Schrute</span>
            </div>
            <div className="col check">
              <X />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar size="default" src="/img/dummy/avatars/JulesWinnfield.jpg">
                M
              </Avatar>
              <span>&nbsp;&nbsp;Jules Winnfield</span>
            </div>
            <div className="col check">
              <Late />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar size="default" src="/img/dummy/avatars/lukeSkywalker.jpeg">
                M
              </Avatar>
              <span>&nbsp;&nbsp;Luke Skywalker</span>
            </div>
            <div className="col check">
              <Late />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar size="default" src="/img/dummy/avatars/IndianaJones.jpg">
                M
              </Avatar>
              <span>&nbsp;&nbsp;Indiana Jones</span>
            </div>
            <div className="col check">
              <Check />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar size="default" src="/img/dummy/avatars/Bruce_Wayne.jpg">
                M
              </Avatar>
              <span>&nbsp;&nbsp;Bruce Wayne</span>
            </div>
            <div className="col check">
              <Check />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
          <div className="attendance-row">
            <div className="col name">
              <label className="custom-checkbox">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
              &nbsp;&nbsp;
              <Avatar size="default" src="/img/dummy/avatars/harryPotter.jpg">
                M
              </Avatar>
              <span>&nbsp;&nbsp;Harry Potter</span>
            </div>
            <div className="col check">
              <Check />
            </div>
            <div className="col more">
              <span className="hi">✋</span>
              <ActionMenu />
            </div>
          </div>
        </div>
      </div>
    </AttendanceContainer>
  );
};

const AttendanceContainer = styled.div`
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    ${tw`text-black-2`};
  }
  .attendance-header {
    ${tw`flex items-center justify-between`};
    .view {
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      ${tw`flex items-center text-primary cursor-pointer`};
    }
  }

  .attendance-card {
    ${tw`grid grid-cols-4 gap-4`};
    .item {
      border-radius: 16.8816px;
      height: 121px;
      ${tw`p-4`};
      p {
        font-weight: 600;
        font-size: 12.3333px;
        line-height: 18px;
        ${tw`text-black-2 mb-4`};
      }
      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 35px;
        ${tw`text-black-2`};
      }
      &.total {
        background: rgba(18, 75, 242, 0.1);
      }
      &.present {
        background: rgba(54, 165, 147, 0.1);
      }
      &.absent {
        background: rgba(250, 52, 52, 0.1);
      }
      &.late {
        background: rgba(254, 141, 3, 0.1);
      }
    }
  }

  .take-attendance {
    label {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      ${tw`flex items-center relative mb-3.5 text-gray cursor-pointer`};
      .checkmark {
        width: 24px;
        height: 24px;
        border: 1.5px solid rgba(73, 79, 177, 0.1);
        // z-index: -1;
        ${tw`absolute left-0 top-0 rounded-full bg-white-4`};
      }
      input {
        &[type='radio'] {
          opacity: 0;
          width: 24px;
          height: 24px;
          z-index: 1;
          ${tw`mr-2.5 cursor-pointer`};

          &:checked {
            & + .checkmark {
              ${tw`bg-success`};
            }
          }
        }
      }
    }

    .action {
      ${tw`flex items-center mt-8`};
      button {
        height: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        ${tw`inline-flex items-center rounded-md py-2 px-3 border-none text-white`};
        &.notify {
          ${tw`bg-black-2`};
        }
        &.submit {
          ${tw`bg-green-100`};
        }
      }
    }
  }

  .students {
    ${tw`border border-gray-3 rounded-md p-4`};
    .filter-container {
      ${tw`grid grid-cols-3 gap-1 mb-4`};
      .select {
        ${tw`col-span-1`};
        .filter__control {
          height: 40px;
          ${tw`border-gray-12 shadow-none! rounded-md`};
          .icon {
            font-size: 14px;
            ${tw`ml-1`};
          }
          .filter__placeholder {
            font-size: 14px;
          }
        }
        .filter__indicator-separator {
          display: none;
        }
      }
      .search {
        ${tw`col-span-2`};
        .ant-input-affix-wrapper {
          height: 40px;
          ${tw`py-0 px-3 rounded-md shadow-none! border-gray-12 text-gray text-base`};
          input {
            ${tw`text-gray`};
          }
        }
      }
    }
  }

  .attendance-table {
    .attendance-row {
      ${tw`grid grid-cols-5 items-center mb-2 p-2`};
      &:nth-child(even) {
        ${tw`bg-white-5 rounded-sm`};
      }
      .col {
        ${tw`col-auto`};
        &.name {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          ${tw`col-span-3 flex items-center text-black-1`};
          .avatar {
            ${tw`relative mx-2`};
          }
        }
        &.check {
          font-size: 20px;
        }
        &.more {
          ${tw`flex items-center justify-end`};
          span {
            width: 24.29px;
            height: 24.29px;
            border: 0.433824px solid #a6a6a6;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            ${tw`inline-flex items-center justify-center rounded-full cursor-pointer`};
            &.hi {
              ${tw`mr-1.5`};
            }
          }
        }

        .avatar {
          overflow: initial;
          ${tw`relative`};
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            ${tw`absolute bg-green-100 rounded-full bottom-0 right-0 border border-white`};
          }
        }
      }

      &.header {
        ${tw`mb-2 text-black-2`};
        .col {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;

export default Attendance;
