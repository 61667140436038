import React, { useState } from 'react';
import { Modal } from 'antd';
import tw from 'twin.macro';
import styled from 'styled-components';
import { BellOutline } from 'src/icons';

type PropsType = {
  open: boolean;
  onConfirm: (checked: boolean) => void;
  onCancel?: () => void;
};

const AttendanceModal: React.FC<PropsType> = ({ open, onConfirm, onCancel }) => {
  const [checked, setChecked] = useState(false);
  return (
    <Modal
      zIndex={10005}
      centered
      closable={true}
      visible={open}
      modalRender={(Child) => <Content>{Child}</Content>}
      footer={null}
      onCancel={onCancel}
    >
      <>
        <h2>Take Attendance</h2>
        <p className="message">Confirm that you would like to notify all students in class</p>
        <label className="mt-3 request">
          <input
            type="checkbox"
            className="form-checkbox cursor-pointer h-4 w-4 border border-red-1"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span>Request all students present to raise their hands ✋ for attendance</span>
        </label>
        <div className="text-center mt-8">
          <button
            role="notify"
            className="btn btn-primary notify"
            onClick={() => onConfirm && onConfirm(checked)}
          >
            Notify all students now <BellOutline className="ml-3" />
          </button>
        </div>
      </>
    </Modal>
  );
};

const Content = styled.div`
  ${tw`bg-white shadow-md rounded-2xl`};
  .ant-modal-content {
    ${tw`rounded-2xl p-9`};
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    ${tw`mb-3`};
  }
  .message {
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    ${tw`mb-4`};
  }
  .request {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
    ${tw`text-black`};
    input {
      ${tw`mr-2`};
    }
  }

  .notify {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
  }
`;
export default AttendanceModal;
